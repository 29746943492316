import axios from "axios";

export const getCategoris = async () => {
    const token = localStorage.getItem('studio:token');
    try {
        const response = await axios.get('https://api.metube.co.in/api/v1/categories', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;

    } catch (error) {
        console.error('Error fetching categories', error);
    }
}