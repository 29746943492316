import axios from "axios";

interface PostVideoStreamInterface {
  category: string;
  description: string;
  title: string;
  thumbnail: File | undefined;
  visibility: string;
}

export const postVideoStream = async (data: PostVideoStreamInterface) => {
  try {
    const token = localStorage.getItem("studio:token");
    console.log("info", data);
    const formData = new FormData();
    formData.append("categoryId", data.category);
    formData.append("description", data.description);
    formData.append("title", data.title);
    formData.append("thumbnail", data.thumbnail ?? "");
    formData.append("status", data.visibility);

    const formDataEntries = formData.entries();
    for (const pair of formDataEntries) {
      console.log(pair[0], pair[1]);
    }

    const response = await axios.post(
      "https://api.metube.co.in/api/v1/videos/stream",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user details", error);
  }
};
