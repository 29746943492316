import { useEffect, useState } from "react";
import {
  LivestreamLayout,
  // PaginatedGridLayout,
  StreamCall,
  StreamVideo,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
// import { ViewerHeader } from './ui/ViewerHeader';
// import { ViewerControls } from './ui/ViewerControls';
import { useInitVideoClient } from "../hooks/useInitVideoClient";
import { useSetCall } from "../hooks/useSetCall";
import { Lobby } from "./ui/Lobby";

export const WebRTCLivestream = () => {
  const { useIsCallLive } = useCallStateHooks();
  const isLive = useIsCallLive();
  const client = useInitVideoClient({
    isAnon: true,
  });
  const call = useSetCall(client);
  const [autoJoin, setAutoJoin] = useState(true);

  useEffect(() => {
    if (!(call && autoJoin)) {
      return;
    }
    call.join().catch((error) => console.error("Error joining call", error));
  }, [call, autoJoin]);

  if (!client) {
    return null;
  }

  return (
    <StreamVideo client={client}>
      {(!autoJoin || !isLive) && (
        <Lobby
          autoJoin={autoJoin}
          isStreaming={isLive}
          setAutoJoin={setAutoJoin}
        />
      )}
      {call && isLive && autoJoin && (
        <StreamCall call={call}>
          <LivestreamLayout
            muted={false}
            showParticipantCount={true}
            showDuration={true}
            showLiveBadge={true}
            showSpeakerName={false}
            floatingParticipantProps={{
              muted: false,
              showParticipantCount: true,
              enableFullScreen: false,
              showDuration: true,
              showLiveBadge: true,
              showSpeakerName: false,
              position: "top-right",
            }}
          />
        </StreamCall>
      )}
    </StreamVideo>
  );
};

// const WebRTCLivestreamUI = () => {
//   return (
//     <>
//       <ViewerHeader />
//       <PaginatedGridLayout />
//       <ViewerControls />
//     </>
//   );
// };
