import { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  TextField,
  Avatar,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle,
  VideoCall,
  Search,
  History,
  ThumbUp,
  Home,
  Whatshot,
  Sensors,
  YouTube,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';
// import { signOut } from '../actions/authActions';
import "./AppBar.scss";

const Navbar = () => {
  const [drawer, setDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  //   const [subscriptions, setSubscriptions] = useState([]);
  //   const [channelLength, setChannelLength] = useState(3);

  //   const currentUser = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  const url = "https://metube.co.in";
  const userData = JSON.parse(localStorage.getItem("studio:user"));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    if (searchText) {
      navigate(`/search?search-query=${searchText}`);
    }
  };

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const handleSignOut = () => {
    // dispatch(signOut());
    // setAnchorEl(null);
  };

  // Fetch subscriptions for authenticated user
  // const fetchSubscriptions = async () => {
  //     if (isAuthenticated) {
  // Simulate API call to fetch subscriptions
  //   const channels = [
  //     { channelName: 'Channel 1', photoUrl: 'avatar1.jpg' },
  //     { channelName: 'Channel 2', photoUrl: 'avatar2.jpg' },
  //     // Add more channels
  //   ];
  //   setSubscriptions(channels);
  // }
  // };

  // useEffect(() => {
  //     fetchSubscriptions();
  // }, [ isAuthenticated ]);

  useEffect(() => {
    (() => {
      const token = localStorage.getItem("studio:token");
      if (!token) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
      }
    })();
  }, []);

  return (
    <>
      <AppBar position="fixed" color="default">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <Link to={url} className="brand-logo">
              MeTube
            </Link>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleSearch}>
                  <Search />
                </IconButton>
              ),
            }}
            style={{ position: "absolute", width: "40%", marginLeft: "15rem" }}
          />
          {isAuthenticated ? (
            <>
              {/* <Tooltip title="Create a video and more">
                <IconButton>
                  <VideoCall />
                </IconButton>
              </Tooltip> */}
              <Link to={`${url}/channels/${userData?._id}`}>
                <Avatar
                  alt="Remy Sharp"
                  src={`https://api.metube.co.in/uploads/avatars/${userData?.photoUrl}`}
                  sx={{ width: 48, height: 48 }}
                />
              </Link>

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <Avatar src={""} alt={""} />
                  <Typography>{""}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  component={Link}
                  to={`/channels/${"currentUser._id"}`}
                >
                  Your channel
                </MenuItem>
                <MenuItem component={Link} to="/studio">
                  MeTube Studio
                </MenuItem>
                <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/signin"
            >
              <AccountCircle /> Sign In
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawer} onClose={toggleDrawer}>
        <div className="drawer-content">
          <List>
            <ListItem button component={Link} to={url}>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to={`${url}/live`}>
              <ListItemIcon>
                <Sensors />
              </ListItemIcon>
              <ListItemText primary="Live" />
            </ListItem>
            <ListItem button component={Link} to={`${url}/trending`}>
              <ListItemIcon>
                <Whatshot />
              </ListItemIcon>
              <ListItemText primary="Trending" />
            </ListItem>
            <ListItem button component={Link} to={`${url}/subscriptions`}>
              <ListItemIcon>
                <YouTube />
              </ListItemIcon>
              <ListItemText primary="Subscriptions" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={`${url}/history`}>
              <ListItemIcon>
                <History />
              </ListItemIcon>
              <ListItemText primary="History" />
            </ListItem>
            <ListItem button component={Link} to={`${url}/liked-videos`}>
              <ListItemIcon>
                <ThumbUp />
              </ListItemIcon>
              <ListItemText primary="Liked videos" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default Navbar;
