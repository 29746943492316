import axios from "axios"

export const getDetails = async (token: string) => {
    try {
        const response = await axios.post('https://api.metube.co.in/api/v1/auth/me', {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;

    } catch (error) {
        console.error('Error fetching user details', error);
    }
}