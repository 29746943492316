import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  PaginatedGridLayout,
  StreamCall,
  useConnectedUser,
  useStreamVideoClient,
} from "@stream-io/video-react-sdk";
import { BackstageHeader } from "./ui/BackstageHeader";
import { BackstageControls } from "./ui/BackstageControls";
import { useSetCall } from "../hooks/useSetCall";
import AppBar from "../AppBar";

export const Backstage = () => {
  const { callId } = useParams();
  const client = useStreamVideoClient();
  const connectedUser = useConnectedUser();
  const call = useSetCall(client);

  useEffect(() => {
    console.log("Setting call", call, connectedUser);
    if (!(call && connectedUser)) return;
    console.log("Joining call", call, connectedUser);
    call
      .join({
        create: true,
        data: { members: [{ user_id: connectedUser.id, role: "host" }] },
      })
      .catch((error) => console.error("Error joining call", error));
  }, [call, connectedUser]);

  if (!callId) return <h3>No Call ID is provided</h3>;
  if (!connectedUser) return <h3>Loading...</h3>;

  // make it scrollable
  return (
    <>
      <AppBar />
      {call && (
        <StreamCall call={call}>
          <BackstageUI />
        </StreamCall>
      )}
    </>
  );
};

const BackstageUI = () => {
  // make it scrollable
  return (
    <div
      style={{
        overflowY: "auto",
        height: "100%",
        color: "white",
      }}
    >
      <BackstageHeader />
      <PaginatedGridLayout />
      <BackstageControls />
    </div>
  );
};
