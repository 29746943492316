import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { LoadingIndicator } from "@stream-io/video-react-sdk";

type LobbyProps = {
  autoJoin: boolean;
  isStreaming: boolean;
  setAutoJoin: (join: boolean) => void;
};

export const Lobby = ({ isStreaming, autoJoin, setAutoJoin }: LobbyProps) => {
  return (
    <div>
      <Typography variant="h4">
        <LoadingIndicator
          type="spinner"
          className="loading-indicator"
          text={
            isStreaming
              ? "Stream is ready!"
              : "Waiting for the livestream to start"
          }
        />
      </Typography>
      <div className="auto-join-container">
        <img
          src="https://api.metube.co.in/uploads/thumbnails/thumbnail-66ee89470678a23b926c6d4d.jpeg"
          width="500px"
          height="auto"
        ></img>
      </div>
    </div>
  );
};
