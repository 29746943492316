import { Typography } from "@mui/material";

export const NotFound = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}>
            <Typography style={{
                // color: 'black',
            }} variant="h3">404 Not Found</Typography>
        </div>
    );
};