import { StreamVideoClient } from "@stream-io/video-react-sdk";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { getUser } from "../utils/getUser";
import { getURLCredentials } from "../utils/getURLCredentials";
import { useParams } from "react-router-dom";
import { DEFAULT_CALL_TYPE } from "../utils/constants";

// const envApiKey = import.meta.env.VITE_STREAM_API_KEY as string;
// const tokenProviderUrl = import.meta.env.VITE_TOKEN_PROVIDER_URL as string;
const envApiKey = "azabr68ejguu";
const tokenProviderUrl =
  "https://stream-calls-dogfood.vercel.app/api/auth/create-token";

type VideoClientProviderProps = {
  isAnon?: boolean;
};

export const useInitVideoClient = ({
  isAnon,
}: PropsWithChildren<VideoClientProviderProps>) => {
  const { callId } = useParams<{ callId: string }>();
  const { api_key, token, type } = getURLCredentials();
  const user = useMemo(() => {
    if (isAnon) {
      return { id: "!anon" };
    }
    return getUser();
  }, [isAnon]);
  const apiKey = api_key ?? envApiKey;

  const [client, setClient] = useState<StreamVideoClient>();

  useEffect(() => {
    const tokenProvider = async () => {
      console.log("Fetching token");
      const endpoint = new URL(tokenProviderUrl);
      endpoint.searchParams.set("api_key", apiKey);
      endpoint.searchParams.set("user_id", isAnon ? " " : user.id);

      if (isAnon) {
        endpoint.searchParams.set(
          "call_cids",
          `${type ?? DEFAULT_CALL_TYPE}:${callId}`
        );
      }
      console.log("Fetching token from", endpoint.toString());
      // let response;
      // try {
      //   response = await fetch(endpoint.toString()).then((res) => res.json());
      // } catch (error) {
      //   console.log("Error fetching token", error);
      // }
      return isAnon
        ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiIWFub24ifQ.zymPi6-tZ21niUrrYDEZ7ClQ5gwZuFhdghvOUM9_7pI"
        : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidmFpYmhhdjgwIn0.z9zY2u6r0aItCp3MoI3_0sDGnMGY4EThmyHO3ap9aPw";
    };
    const _client = new StreamVideoClient({
      apiKey,
      ...((isAnon || !token) && { tokenProvider }),
      ...(!isAnon && { token }),
      user: isAnon ? { type: "anonymous" } : user,
    });
    setClient(_client);

    return () => {
      _client
        .disconnectUser()
        .catch((error) => console.error(`Unable to disconnect user`, error));
      setClient(undefined);
    };
  }, [apiKey, callId, isAnon, token, type, user]);

  return client;
};
