import { Stack} from '@mui/material';
import AppBar from '../AppBar';
import LiveStreamForm from './LiveStreamForm';

export const SetupLivestream = () => {

  return (
    <>
      <AppBar />
      <Stack justifyContent="center" alignItems="center" flexGrow={1} spacing={4}>
        {/* <Typography style={{ color: "black" }} variant="h3">Setup Livestream</Typography>
        <Input
          placeholder="Enter title"
          value={callId}
          onChange={(e) => {
            setCallId(e.target.value);
          }} /> */}
        <LiveStreamForm />
      </Stack>
    </>
  );
};
