import { CallRecording, CallRecordingList, useCall } from '@stream-io/video-react-sdk';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const CallRecordings = () => {
  const call = useCall();
  const { callId } = useParams<{ callId?: string }>();
  const callRecordings: CallRecording[] = useMemo(() => [], []);

  useEffect(() => {
    if (!call) return;
    call.queryRecordings(callId).then((recordings) => {
      recordings.recordings.forEach((recording) => {
        callRecordings.push(recording);
      });
    });
  }, [call, callId, callRecordings]);


  return <CallRecordingList callRecordings={callRecordings} />;
};