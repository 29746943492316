import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { Image as ImageIcon } from "@mui/icons-material";
import { getDetails } from "../services/getDetails";
import { getCategoris } from "../services/getCategoris";
import { postVideoStream } from "../services/postVideoStream";
// import { postVideoStream } from "../services/postVideoStream";

interface FormData {
  title: string;
  description: string;
  category: string;
  visibility: "private" | "public";
}

const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"),
  description: yup
    .string()
    .required("Description is required")
    .min(3, "Description must be at least 3 characters"),
  visibility: yup
    .string()
    .oneOf(["private", "public"])
    .required("Visibility is required"),
  category: yup
    .string()
    .required("Category is required")
    .min(3, "Category must be at least 3 characters"),
});

interface Category {
  title: string;
  _id: string;
}

const VideoDetails: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [imgDataUrl, setImgDataUrl] = useState<string | null>(null);
  // const [ inputLoading, setInputLoading ] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);

  const { id } = useParams<{ id: string }>();
  const navigator = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token =
        localStorage.getItem("studio:token") ||
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
      localStorage.setItem("studio:token", token);

      try {
        const response = await getDetails(token);
        localStorage.setItem("studio:user", JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchCategories = async () => {
      try {
        const response = await getCategoris();
        const categories: Category[] = response.data.map(
          (category: Category) => ({
            title: category.title,
            _id: category._id,
          })
        );
        setCategories(categories);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserDetails();
    fetchCategories();
  }, [id, setValue]);

  const onSubmit = async (data: FormData) => {
    setSubmitLoading(true);

    try {
      const category = categories.find((cat) => cat.title === data.category);
      console.log(category);

      const obj = {
        category: category?._id ?? "",
        description: data.description,
        title: data.title,
        visibility: data.visibility,
      };
      const response = await postVideoStream({ ...obj, thumbnail: imageFile });
      localStorage.setItem("studio:stream", JSON.stringify(response.data));
      navigator(`/backstage/${response.data._id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };
  const handlePickImage = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/jpg";
    input.onchange = (event: Event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      setImageFile(file);
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImgDataUrl(e.target?.result as string);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  return (
    <div style={{ padding: "16px", width: "70%" }}>
      <Typography variant="h4" gutterBottom style={{ marginTop: "20px" }}>
        Stream details
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={8}>
          <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset()}>
            {/* Title Field */}
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title (required)"
                  fullWidth
                  variant="filled"
                  error={!!errors.title}
                  helperText={errors.title ? errors.title.message : ""}
                  inputProps={{ maxLength: 100 }}
                />
              )}
            />

            {/* Description Field */}
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  style={{ marginTop: "16px" }}
                  fullWidth
                  variant="filled"
                  multiline
                  rows={5}
                  error={!!errors.description}
                  helperText={
                    errors.description ? errors.description.message : ""
                  }
                  inputProps={{ maxLength: 5000 }}
                />
              )}
            />

            {/* Visibility Select */}
            <Controller
              name="visibility"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  variant="filled"
                  style={{ marginTop: "16px" }}
                >
                  <InputLabel>Visibility</InputLabel>
                  <Select {...field} error={!!errors.visibility}>
                    <MenuItem value="public">Public</MenuItem>
                    <MenuItem value="private">Private</MenuItem>
                  </Select>
                  {errors.visibility && (
                    <Typography color="error" variant="body2">
                      {errors.visibility.message}
                    </Typography>
                  )}
                </FormControl>
              )}
            />

            {/* Category Select */}
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  variant="filled"
                  style={{ marginTop: "16px" }}
                >
                  <InputLabel>Category</InputLabel>
                  <Select {...field} error={!!errors.category}>
                    {categories.map((category) => (
                      <MenuItem key={category._id} value={category.title}>
                        {category.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.category && (
                    <Typography color="error" variant="body2">
                      {errors.category.message}
                    </Typography>
                  )}
                </FormControl>
              )}
            />

            <div
              style={{
                marginTop: "24px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color={imgDataUrl !== null ? "primary" : "inherit"}
                disabled={submitLoading || imgDataUrl === null}
              >
                {submitLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Create Live Stream"
                )}
              </Button>
            </div>
          </form>
        </Grid>

        <Grid item xs={4}>
          {/* <Button variant="text" onClick={handleUploadToggle}>
                        Upload Thumbnails
                    </Button> */}

          {/* {showUpload && ( */}
          {/* // <MyUpload
                        //   field="thumbnail"
                        //   onCropSuccess={(imgDataUrl: string) => setImgDataUrl(imgDataUrl)}
                        //   method="PUT"
                        //   width={998}
                        //   height={578}
                        //   url={`${process.env.REACT_APP_URL}/api/v1/videos/${id}/thumbnails`}
                        //   headers={{ Authorization: `Bearer ${localStorage.getItem("token")}` }}
                        // />
                        <>
                            uploaded image
                        </>
                    )} */}

          {imgDataUrl ? (
            <img
              src={imgDataUrl}
              alt="Thumbnail"
              style={{ width: "100%", height: "350px" }}
            />
          ) : (
            <div
              style={{
                border: "2px dashed #ccc",
                borderRadius: "8px",
                height: "250px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
              onClick={handlePickImage}
            >
              <ImageIcon fontSize="large" />
              <Typography variant="body2" color="textSecondary">
                Click to upload <span style={{ color: "red" }}>*</span>
              </Typography>
            </div>
          )}
          {imgDataUrl && (
            <Button
              variant="text"
              style={{ marginTop: "8px" }}
              onClick={() => setImgDataUrl(null)}
            >
              Remove
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default VideoDetails;
